<template>
  <v-btn to="/" text plain class="text-none text-6 px-0">{{ title }}</v-btn>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "SiteTitle",
  computed: {
    ...mapState({
      title: (state) => state.config.title,
    }),
  },
};
</script>

<style>
</style>