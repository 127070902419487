<template>
  <div class="pt-4 px-1">
    <v-card-actions>
        <v-btn to="/login" color="primary" block>로그인</v-btn>
    </v-card-actions>
    <v-card-actions>
        <v-btn to="/join" color="secondary" block>회원가입</v-btn>
    </v-card-actions>
  </div>
</template>

<script>
export default {
    name : "NoMemberMenu"
};
</script>

<style>

</style>